<template>
  <div>
    <BaseHeaderBar title="Rincian" />
    <img class="detail-icon" src="@/assets/img/repay-icon.png" />
    <div class="top-block">
      <p class="p1">Nama Pengguna: {{ repaymentDetail.cudrstomerName }}</p>
      <p class="p2">{{ repaymentDetail.badrnkName }}（{{ repaymentDetail.badrnkAccount }}）</p>
      <p class="p3">Jumlah pinjaman sudah ditransfer ke rekening bank ini</p>
    </div>
    <div class="detail-list">
      <div>
        <p>Tanggal pinjaman</p>
        <p>{{ repaymentDetail.ardrrivalTime }}</p>
      </div>
      <div>
        <p>Jumlah pinjaman</p>
        <p>Rp{{ repaymentDetail.lodranAmount }}</p>
      </div>
      <div>
        <p>Lama pinjaman</p>
        <p>{{ repaymentDetail.tidrmers }}</p>
      </div>
      <div>
        <p>Jumlah keterlambatan</p>
        <p>{{ repaymentDetail.ovdrerdueDay }} Hari</p>
      </div>
      <div>
        <p>Denda keterlambatan</p>
        <p>Rp{{ repaymentDetail.ovdrerdueInterestpenalty }}</p>
      </div>
      <div>
        <p>Biaya penundaan</p>
        <p>Rp{{ repaymentDetail.ovdrerdueLiquidateddamages }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  name: 'RepaymenDetail',
  data () {
    return {
      repaymentDetail: {}
    }
  },
  created () {
    this.getRepaymentDetail()
  },
  methods: {
    getRepaymentDetail () {
      request.post('homeDRInterface')
        .then(res => {
          this.repaymentDetail = res.bidrllDetails
        })
    }
  }
}
</script>

<style scoped lang="less">
.detail-icon {
  width: 647px;
  height: 45px;
  margin: 18px auto 28px;
}
.top-block {
  p {
    font-size: 26px;
    color: #FFF;
    text-align: center;

    &:nth-child(n+2) {
      margin-top: 8px;
    }
  }

  .p1 {
    font-size: 26px;
    color: #333;
  }

  .p2 {
    font-size: 32px;
    font-weight: 500;
    color: #333;
  }

  .p3 {
    font-size: 24px;
    color: #999;
  }
}

.detail-list {
  border-top: 2px solid #A0A0A0;
  margin: 14px 47px 0;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 92px;
    line-height: 92px;
    border-bottom: 2px solid #A0A0A0;

    p {
      font-size: 26px;
      color: #333;
    }
  }
}
</style>
